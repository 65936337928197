.new-target-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  max-width: 26rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 5px;
}
form {
  display: flex;
  flex-direction: column;
  width: 90%;
}
input {
  margin-bottom: 1rem;
  border-width: 0 0 1px;
}
textarea {
  margin-bottom: 1rem;
  border-width: 0 0 1px;
  font-family: "Open Sans";
  height: 5rem;
}
button {
  border-radius: 3px;
  height: 2rem;
  width: 100%;
  background: transparent;
  border: 1px solid black;
}
.submit-button:hover {
  background-color: rgba(0, 128, 0, 0.733);
}
@media only screen and (max-width: 600px) {
  .new-target-form-container {
    width: 78%;
  }
  .submit-button:hover {
    background-color: white;
  }
}
