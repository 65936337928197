.target-list {
  margin: 0 auto;
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .target-list {
    margin-top: 3rem;
  }
}
@media only screen and (min-width: 600px) {
  .target-list {
    width: 80%;
  }
}
