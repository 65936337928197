@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
.header {
  height: 3.5rem;
  background-color: grey;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0px 1px 1px rgb(94, 94, 94);
  z-index: 15;
}
.header-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 95%;
  margin: 0 auto;
}
.header-links-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 95%;
  margin: 0 auto;
}
.icon-desktop {
  color: white;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 2rem;
  display: fixed;
  top: 0;
  left: 1rem;
}
.icon-mobile {
  color: white;
  font-size: 2.5rem;
}
.show-bars {
  color: white;
  font-size: 2.5rem;
  transition: 400ms;
  cursor: pointer;
  transform: rotate(360deg);
}
.hide-bars {
  color: white;
  font-size: 2.5rem;
  transition: 400ms;
  cursor: pointer;
}
.hide-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: grey;
  height: 100%;
  position: fixed;
  right: 0;
  box-shadow: -1px 1px 1px rgb(94, 94, 94);
  overflow-y: scroll;
  width: 0rem;
  transition: 400ms;
}
.show-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: grey;
  height: 100%;
  position: fixed;
  right: 0;
  box-shadow: -1px 1px 1px rgb(94, 94, 94);
  overflow-y: scroll;
  width: 20rem;
  transition: 400ms;
}
.mobile-menu-contents h2 {
  color: white;
  margin-left: 0rem;
  text-align: left;
  cursor: pointer;
  transition: 500ms;
}
.mobile-menu-contents h2:hover {
  color: black;
}

.menu-category {
  transition: 400ms;
  padding: 0;
  margin-bottom: 0;
}

.hide-menu-contents {
  display: none;
}
.show-menu-contents {
  display: contents;
}
.close-button {
  color: white;
  margin-top: 2rem;
  font-size: 2rem;
  transition: 400ms;
  cursor: pointer;
}
.close-button:hover {
  color: black;
  transform: rotate(180deg);
}
ul {
  margin-top: 1rem;
  list-style: none;
  padding-left: 0;
}
li {
  font-size: 1.5rem;
}
.awesome {
  font-size: 1rem;
  margin-left: 1rem;
  margin-bottom: 0.1rem;
}
.menu-targets {
  z-index: 30;
  text-align: left;
  margin-right: 0 auto;
  margin-bottom: 3rem;
  cursor: pointer;
  color: white;
  transition: 500ms;
}
.show-category {
  height: auto;
  transform: scale(1);
}
.hide-category {
  height: 0;
  transform: scaleY(0);
}
.menu-targets:hover {
  color: black;
}
.menu-targets {
  color: white;
}
a,
a:link {
  text-decoration: none;
}
.new-target {
  margin-top: 2rem;
}
@media only screen and (max-width: 600px) {
  .icon-desktop {
    display: none;
  }
  .header-links {
    width: 92%;
  }
  .menu-targets {
    margin-bottom: 2rem;
  }
  .mobile-menu-contents h2:hover {
    color: white;
  }
  .close-button:hover {
    color: white;
  }
  .menu-targets:hover {
    color: white;
  }
}
