@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(238, 238, 238);
  box-sizing: border-box;
}
