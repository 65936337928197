.next-previous-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
  width: 100%;
}
.next-button-only {
  display: flex;
  justify-content: flex-end;
  margin-top: -2rem;
  width: 100%;
}
.next-previous-buttons button {
  width: 8rem;
}
.next-button-only button {
  width: 8rem;
}
.name {
  margin-top: 3rem;
}
.company-logo {
  margin-top: -7rem;
}
.target-info {
  margin: 5rem auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 80%;
  border-radius: 5px;
  padding: 3rem;
}
.target-info img {
  max-height: 13rem;
}
.hide-target-info {
  display: none;
}
.target-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: -5rem;
}
.details-content {
  margin: 1rem 2rem;
}
.contact {
  display: flex;
  flex-direction: column;
}
.details-content p {
  margin-top: 0.5rem;
}
.details-content a {
  color: black;
}
.hide-form {
  display: none;
}
.comma-directions {
  opacity: 0.8;
  font-size: 0.5rem;
}
.next-previous-buttons button:hover {
  background-color: rgb(218, 218, 218);
}
.next-button-only button:hover {
  background-color: rgb(218, 218, 218);
}
.edit-delete-button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 0rem;
  width: 70%;
}
.edit-cancel-button {
  cursor: pointer;
}
.delete-target-button {
  cursor: pointer;
}
.delete-target-button:hover {
  background-color: rgb(206, 36, 36);
}

@media only screen and (max-width: 600px) {
  .target-info {
    margin-top: 4rem;
    padding: 1rem;
    width: 90%;
  }
  .details-content {
    margin: 1rem;
  }
  .next-previous-buttons {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
  .next-button-only {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
  button:hover {
    background-color: white;
  }
  .next-previous-buttons button:hover {
    background-color: white;
  }
  .next-button-only button:hover {
    background-color: white;
  }
  .delete-target-button:hover {
    background-color: white;
  }
}
@media only screen and (min-width: 800px) {
  .target-info {
    width: 60%;
  }
}
