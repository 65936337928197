@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
p {
  font-family: "Open Sans";
}
.target-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 2rem;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  max-width: 30rem;
  margin-top: 0rem;
}
.target-items img {
  max-height: 12rem;
}
.target-heading {
  margin-top: 1rem;
  margin-bottom: -2rem;
}
.target-heading a {
  text-decoration: none;
  color: black;
}
.target-heading:hover {
  opacity: 0.8;
}
.green {
  background-color: rgba(0, 128, 0, 0.7);
}
.blue {
  background-color: rgb(0, 132, 255, 0.7);
}
.yellow {
  background-color: rgb(252, 255, 100, 0.7);
}
.red {
  background-color: rgba(255, 0, 0, 0.7);
}
.contact-header {
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.contact-container a {
  color: black;
}
.learn-more-container {
  border: 1px solid black;
  padding: 0rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.learn-more-container:hover {
  background-color: rgb(218, 218, 218);
}
.learn-more-container p {
  color: black;
  text-decoration: none;
  padding: 0rem;
}
a:link {
  text-decoration: none;
}
.status {
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.1rem;
  border-radius: 10px;
  min-width: 15rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status-selector {
  border: none;
  text-align: center;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
form {
  text-align: left;
}
label {
  font-weight: 700;
  margin-top: 1.5rem;
}
.hide-form {
  display: none;
}
.show-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.image-container {
  display: flex;
  justify-content: center;
}
.show-info {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0.5rem auto;
}
.show-form input {
  font-size: 1rem;
  margin: 0.5rem auto;
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
  background-color: white;
  height: 2rem;
}
.show-form textarea {
  font-size: 1rem;
  margin: 0.5rem auto;
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
  background-color: white;
  min-width: 5rem;
  font-family: "Open Sans";
}
.save-button {
  border-radius: 5px;
  height: 2rem;
  width: 15rem;
  margin: 0 auto;
  background-color: green;
  border: none;
  color: white;
  margin-bottom: 2rem;
}
.show-form button:hover {
  background-color: rgb(4, 163, 4);
}

.delete-button {
  border-radius: 3px;
  height: 2rem;
  width: 100%;
  background-color: white;
  border: 1px solid black;
}
.delete-button:hover {
  background-color: rgb(206, 36, 36);
}
.edit-cancel-button {
  border-radius: 3px;
  height: 2rem;
  width: 100%;
  background: transparent;
  border: 1px solid black;
}
.edit-cancel-button:hover {
  background-color: rgb(218, 218, 218);
}
.edit-delete-button-container {
  display: flex;
  justify-content: center;
  margin-top: -1rem;
  margin-bottom: 2rem;
  width: 70%;
}
@media only screen and (max-width: 600px) {
  input,
  textarea,
  select {
    font-size: 16px;
    box-sizing: border-box;
  }
  .target-items {
    width: 95%;
    max-width: none;
    margin: 1rem 0;
  }
  .show-info {
    width: 95%;
  }
  .target-information {
    width: 100%;
    margin: 0 auto;
  }
  .contact-header {
    margin-bottom: 1rem;
  }
  .edit-delete-button-container {
    width: 95%;
  }
  .show-form input {
    min-width: 18rem;
  }
  .show-form textarea {
    max-width: 18rem;
  }
  button:hover {
    background-color: white;
  }
  .target-heading:hover {
    opacity: 1;
  }
  .learn-more-container:hover {
    background-color: white;
  }
  .show-form button:hover {
    background-color: white;
  }
  .delete-button:hover {
    background-color: white;
  }
  .edit-cancel-button:hover {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) {
  .show-form {
    min-width: 30rem;
  }
  .show-form input {
    min-width: 21.5rem;
  }
  .show-form textarea {
    max-width: 21.5rem;
  }
}
