@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(238, 238, 238);
  box-sizing: border-box;
}

* {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
  width: 100%;
}
h2 {
  font-family: "Open Sans";
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button {
  cursor: pointer;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header {
  height: 3.5rem;
  background-color: grey;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0px 1px 1px rgb(94, 94, 94);
  z-index: 15;
}
.header-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 95%;
  margin: 0 auto;
}
.header-links-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 95%;
  margin: 0 auto;
}
.icon-desktop {
  color: white;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 2rem;
  display: fixed;
  top: 0;
  left: 1rem;
}
.icon-mobile {
  color: white;
  font-size: 2.5rem;
}
.show-bars {
  color: white;
  font-size: 2.5rem;
  transition: 400ms;
  cursor: pointer;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.hide-bars {
  color: white;
  font-size: 2.5rem;
  transition: 400ms;
  cursor: pointer;
}
.hide-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: grey;
  height: 100%;
  position: fixed;
  right: 0;
  box-shadow: -1px 1px 1px rgb(94, 94, 94);
  overflow-y: scroll;
  width: 0rem;
  transition: 400ms;
}
.show-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: grey;
  height: 100%;
  position: fixed;
  right: 0;
  box-shadow: -1px 1px 1px rgb(94, 94, 94);
  overflow-y: scroll;
  width: 20rem;
  transition: 400ms;
}
.mobile-menu-contents h2 {
  color: white;
  margin-left: 0rem;
  text-align: left;
  cursor: pointer;
  transition: 500ms;
}
.mobile-menu-contents h2:hover {
  color: black;
}

.menu-category {
  transition: 400ms;
  padding: 0;
  margin-bottom: 0;
}

.hide-menu-contents {
  display: none;
}
.show-menu-contents {
  display: contents;
}
.close-button {
  color: white;
  margin-top: 2rem;
  font-size: 2rem;
  transition: 400ms;
  cursor: pointer;
}
.close-button:hover {
  color: black;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
ul {
  margin-top: 1rem;
  list-style: none;
  padding-left: 0;
}
li {
  font-size: 1.5rem;
}
.awesome {
  font-size: 1rem;
  margin-left: 1rem;
  margin-bottom: 0.1rem;
}
.menu-targets {
  z-index: 30;
  text-align: left;
  margin-right: 0 auto;
  margin-bottom: 3rem;
  cursor: pointer;
  color: white;
  transition: 500ms;
}
.show-category {
  height: auto;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.hide-category {
  height: 0;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}
.menu-targets:hover {
  color: black;
}
.menu-targets {
  color: white;
}
a,
a:link {
  text-decoration: none;
}
.new-target {
  margin-top: 2rem;
}
@media only screen and (max-width: 600px) {
  .icon-desktop {
    display: none;
  }
  .header-links {
    width: 92%;
  }
  .menu-targets {
    margin-bottom: 2rem;
  }
  .mobile-menu-contents h2:hover {
    color: white;
  }
  .close-button:hover {
    color: white;
  }
  .menu-targets:hover {
    color: white;
  }
}

p {
  font-family: "Open Sans";
}
.target-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 2rem;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  max-width: 30rem;
  margin-top: 0rem;
}
.target-items img {
  max-height: 12rem;
}
.target-heading {
  margin-top: 1rem;
  margin-bottom: -2rem;
}
.target-heading a {
  text-decoration: none;
  color: black;
}
.target-heading:hover {
  opacity: 0.8;
}
.green {
  background-color: rgba(0, 128, 0, 0.7);
}
.blue {
  background-color: rgb(0, 132, 255, 0.7);
}
.yellow {
  background-color: rgb(252, 255, 100, 0.7);
}
.red {
  background-color: rgba(255, 0, 0, 0.7);
}
.contact-header {
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.contact-container a {
  color: black;
}
.learn-more-container {
  border: 1px solid black;
  padding: 0rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.learn-more-container:hover {
  background-color: rgb(218, 218, 218);
}
.learn-more-container p {
  color: black;
  text-decoration: none;
  padding: 0rem;
}
a:link {
  text-decoration: none;
}
.status {
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.1rem;
  border-radius: 10px;
  min-width: 15rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status-selector {
  border: none;
  text-align: center;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
form {
  text-align: left;
}
label {
  font-weight: 700;
  margin-top: 1.5rem;
}
.hide-form {
  display: none;
}
.show-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.image-container {
  display: flex;
  justify-content: center;
}
.show-info {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0.5rem auto;
}
.show-form input {
  font-size: 1rem;
  margin: 0.5rem auto;
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
  background-color: white;
  height: 2rem;
}
.show-form textarea {
  font-size: 1rem;
  margin: 0.5rem auto;
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
  background-color: white;
  min-width: 5rem;
  font-family: "Open Sans";
}
.save-button {
  border-radius: 5px;
  height: 2rem;
  width: 15rem;
  margin: 0 auto;
  background-color: green;
  border: none;
  color: white;
  margin-bottom: 2rem;
}
.show-form button:hover {
  background-color: rgb(4, 163, 4);
}

.delete-button {
  border-radius: 3px;
  height: 2rem;
  width: 100%;
  background-color: white;
  border: 1px solid black;
}
.delete-button:hover {
  background-color: rgb(206, 36, 36);
}
.edit-cancel-button {
  border-radius: 3px;
  height: 2rem;
  width: 100%;
  background: transparent;
  border: 1px solid black;
}
.edit-cancel-button:hover {
  background-color: rgb(218, 218, 218);
}
.edit-delete-button-container {
  display: flex;
  justify-content: center;
  margin-top: -1rem;
  margin-bottom: 2rem;
  width: 70%;
}
@media only screen and (max-width: 600px) {
  input,
  textarea,
  select {
    font-size: 16px;
    box-sizing: border-box;
  }
  .target-items {
    width: 95%;
    max-width: none;
    margin: 1rem 0;
  }
  .show-info {
    width: 95%;
  }
  .target-information {
    width: 100%;
    margin: 0 auto;
  }
  .contact-header {
    margin-bottom: 1rem;
  }
  .edit-delete-button-container {
    width: 95%;
  }
  .show-form input {
    min-width: 18rem;
  }
  .show-form textarea {
    max-width: 18rem;
  }
  button:hover {
    background-color: white;
  }
  .target-heading:hover {
    opacity: 1;
  }
  .learn-more-container:hover {
    background-color: white;
  }
  .show-form button:hover {
    background-color: white;
  }
  .delete-button:hover {
    background-color: white;
  }
  .edit-cancel-button:hover {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) {
  .show-form {
    min-width: 30rem;
  }
  .show-form input {
    min-width: 21.5rem;
  }
  .show-form textarea {
    max-width: 21.5rem;
  }
}

input,
textarea,
select {
  font-size: 16px;
  box-sizing: border-box;
}
.form-container {
  display: flex;
  justify-content: center;
  width: 80%;
}
.form-content {
  display: flex;
  justify-content: center;
  width: 100%;
}
.status-selector-container {
  margin-top: 1rem;
}
@media only screen and (max-width: 600px) {
  .form-container {
    width: 100%;
  }
}

.target-list {
  margin: 0 auto;
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .target-list {
    margin-top: 3rem;
  }
}
@media only screen and (min-width: 600px) {
  .target-list {
    width: 80%;
  }
}

.new-target-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  max-width: 26rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 5px;
}
form {
  display: flex;
  flex-direction: column;
  width: 90%;
}
input {
  margin-bottom: 1rem;
  border-width: 0 0 1px;
}
textarea {
  margin-bottom: 1rem;
  border-width: 0 0 1px;
  font-family: "Open Sans";
  height: 5rem;
}
button {
  border-radius: 3px;
  height: 2rem;
  width: 100%;
  background: transparent;
  border: 1px solid black;
}
.submit-button:hover {
  background-color: rgba(0, 128, 0, 0.733);
}
@media only screen and (max-width: 600px) {
  .new-target-form-container {
    width: 78%;
  }
  .submit-button:hover {
    background-color: white;
  }
}

.next-previous-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
  width: 100%;
}
.next-button-only {
  display: flex;
  justify-content: flex-end;
  margin-top: -2rem;
  width: 100%;
}
.next-previous-buttons button {
  width: 8rem;
}
.next-button-only button {
  width: 8rem;
}
.name {
  margin-top: 3rem;
}
.company-logo {
  margin-top: -7rem;
}
.target-info {
  margin: 5rem auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 80%;
  border-radius: 5px;
  padding: 3rem;
}
.target-info img {
  max-height: 13rem;
}
.hide-target-info {
  display: none;
}
.target-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: -5rem;
}
.details-content {
  margin: 1rem 2rem;
}
.contact {
  display: flex;
  flex-direction: column;
}
.details-content p {
  margin-top: 0.5rem;
}
.details-content a {
  color: black;
}
.hide-form {
  display: none;
}
.comma-directions {
  opacity: 0.8;
  font-size: 0.5rem;
}
.next-previous-buttons button:hover {
  background-color: rgb(218, 218, 218);
}
.next-button-only button:hover {
  background-color: rgb(218, 218, 218);
}
.edit-delete-button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 0rem;
  width: 70%;
}
.edit-cancel-button {
  cursor: pointer;
}
.delete-target-button {
  cursor: pointer;
}
.delete-target-button:hover {
  background-color: rgb(206, 36, 36);
}

@media only screen and (max-width: 600px) {
  .target-info {
    margin-top: 4rem;
    padding: 1rem;
    width: 90%;
  }
  .details-content {
    margin: 1rem;
  }
  .next-previous-buttons {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
  .next-button-only {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
  button:hover {
    background-color: white;
  }
  .next-previous-buttons button:hover {
    background-color: white;
  }
  .next-button-only button:hover {
    background-color: white;
  }
  .delete-target-button:hover {
    background-color: white;
  }
}
@media only screen and (min-width: 800px) {
  .target-info {
    width: 60%;
  }
}

