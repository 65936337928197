input,
textarea,
select {
  font-size: 16px;
  box-sizing: border-box;
}
.form-container {
  display: flex;
  justify-content: center;
  width: 80%;
}
.form-content {
  display: flex;
  justify-content: center;
  width: 100%;
}
.status-selector-container {
  margin-top: 1rem;
}
@media only screen and (max-width: 600px) {
  .form-container {
    width: 100%;
  }
}
