@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
* {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
  width: 100%;
}
h2 {
  font-family: "Open Sans";
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button {
  cursor: pointer;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
